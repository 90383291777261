import React from 'react';
import "./PanoOverlay.css";
import "./PanoInterface.js"; 

declare var PanInterface: any;

type panoOverlayProps = {
    currentRoom:string;
    closeElement: () => void;
}
type panoOverlayState = {
    panoIsActivated:boolean;
    pannellumLink:string;
}

export class PanoOverlay extends React.Component<panoOverlayProps,panoOverlayState>{
    readonly pannellum = "";
    panoRef:any;

    constructor(props:panoOverlayProps){
        super(props);
        this.state = {panoIsActivated:true,pannellumLink:this.pannellum};
    }
    
    public closeMe = () => {
        this.setState({panoIsActivated:false});
        this.props.closeElement();
    }
    
    componentDidMount(){
        this.panoRef = PanInterface.InitPano(this.props.currentRoom);
    }

    render(){
        return(
            <div>
                {this.state.panoIsActivated && (
                    <div className="overlay">
                        <button className="closeOverlayButton" onClick={this.closeMe}>X</button>
                        <div id="pano123" className="pannellumStage"></div>
                    </div>
                )}
            </div>
        );
    }
}


export default PanoOverlay;