import React, { RefObject, useState } from 'react';
//import { useAuth0 } from './contexts/auth0-context';
import './App.css';
import { Unity } from './components/unity/Unity';
import SensorDetail from './components/sensorDetail/SensorDetail';
import DataPointList from './components/dataPointList/DataPointList';
//import {APIInteraction} from './contexts/apiInteraction';
import HIT from './components/hit/HIT';
import { TimeSeriesOverlay } from './components/timeSeriesOverlay/TimeSeriesOverlay';
import FloorPlan from './components/floorPlan/FloorPlan';

function App(): JSX.Element{
  //const { loginWithRedirect, logout, isAuthenticated, getTokenSilently} = useAuth0();
  var [sensorName, setSensorName] = useState<string>("default");
  var [hasAccessToBuilding, sethasAccessToBuilding] = useState<boolean>(true);
  //var [hasAccessToBuilding, sethasAccessToBuilding] = useState<boolean>(true);
  var [timeSeriesOverlayClass, settimeSeriesOverlayClass] = useState<string>("TimeSeriesOverlayHidden");
  var [currentRoom, setcurrentRoom] = useState<string>("");
  var [currentFloor, setcurrentFloor] = useState<string>("");

  var unityReference:RefObject<Unity> = React.createRef();
  var hitReference:RefObject<HIT> = React.createRef();
  var timesSeriesOverlayReference:RefObject<TimeSeriesOverlay> = React.createRef();

  function handleSensorChange(event:string){}

  function sensorClick(event:string){
    setSensorName(event);
    handleSensorChange(event);
    if(unityReference.current){
      unityReference.current.navigateInUnityTo(event);
    }else{
      console.error("child current is null");
    }
  }

  function changeTimeSeriesOverlay(deviceString:string){
    settimeSeriesOverlayClass("TimeSeriesOverlay");
    if(timesSeriesOverlayReference.current){
      timesSeriesOverlayReference.current.initOverlay(deviceString);
    }else{
      console.error("TimeSeriesOverLayReference is null");
    }
  }

  function exportedSensorType(type:string){
    if(hitReference.current){
      hitReference.current.changeType(type);
    }else{
      console.error("hitReference is null");
    }
  }


  function onRoomChanged(roomName:string){
    setcurrentFloor("");
    setcurrentRoom(roomName)
  }

  // function login(event:any){
  //   loginWithRedirect();
  // }

  // const LoginAndCheck = () => {
  //   let hasRun = false;
  //   const api = new APIInteraction();
  //   console.log("isAuthenticated");
  //   console.log(isAuthenticated);
  //   if(isAuthenticated){
  //     getTokenSilently().then((token:any) => {
  //       api.setToken(token);
  //       api.getDataPointsRaw().then((response) =>{
  //         if (response.status === 200){
  //           sethasAccessToBuilding(true);
  //         }else {
  //           sethasAccessToBuilding(false);
  //         }
  //       });
  //       hasRun = true;
  //     });
  //   }
    
  //   return (
  //     <div>
  //       <div style={{position:"absolute",top:"30%",left:"40%"}}>Validating your existing BuildingTwin rights.</div>
  //       {!hasAccessToBuilding && hasRun && <div>No access to the technology center building.</div>}
  //     </div>
  //   )
  // }

  // return (
  //   <div className="app-grid-container">
  //     {isAuthenticated && !hasAccessToBuilding && <LoginAndCheck />}
  //     <div className="Unity">
  //       {hasAccessToBuilding && (
  //           <Unity 
  //             ref={unityReference} 
  //             isOn={true} 
  //             sensorName={sensorName} 
  //             onChange={handleSensorChange} 
  //             onNearMeResults={changeTimeSeriesOverlay}
  //             onExportTypeResults={exportedSensorType}
  //             onRoomChanged={onRoomChanged}
  //             ></Unity>
  //         )}
  //     </div>  
  //     <div className="Datapoints">
  //       {hasAccessToBuilding && (
  //         <DataPointList onSensorClick={sensorClick}/>
  //       )}
  //     </div>
  //     <div className="TimeSeries">
  //       {hasAccessToBuilding && 
  //         (
  //           <SensorDetail sensorDetail={sensorName}></SensorDetail>
  //         )
  //       }
  //     </div>
  //     <div className="Hit">
  //       {hasAccessToBuilding &&
  //         <HIT ref={hitReference}/>
  //       }
  //       {hasAccessToBuilding && (
  //         <FloorPlan currentFloor={currentFloor} currentRoom={currentRoom}/>
  //       )}
  //     </div>
  //     <div className={timeSeriesOverlayClass}>
  //       {hasAccessToBuilding && (
  //         <TimeSeriesOverlay ref={timesSeriesOverlayReference}></TimeSeriesOverlay>
  //       )}
  //     </div>
      
  //     <div className="Logo"><img src="./img/siemens-logo.png" alt="Siemens Logo" id="SiemensLogoId"/></div>
  //     <div className="Title">Aspern Wayfinder</div>
  //     <div className="Login">
  //       {isAuthenticated && 
  //         (
  //           <button className="logout-button" onClick={() => logout({ returnTo: (window.location.origin + "/index.html") })}>Log Out </button>
  //         )
  //       }
  //       {!isAuthenticated && 
  //         (
  //           <button className="login-button" onClick={login}>Login</button>
  //         )
  //       }
  //     </div>
  //   </div>
  //   );

  return (
  <div className="app-grid-container">
    <div className="Unity">
      {hasAccessToBuilding && (
          <Unity 
            ref={unityReference} 
            isOn={true} 
            sensorName={sensorName} 
            onChange={handleSensorChange} 
            onNearMeResults={changeTimeSeriesOverlay}
            onExportTypeResults={exportedSensorType}
            onRoomChanged={onRoomChanged}
            ></Unity>
        )}
    </div>  
    <div className="Datapoints">
      {hasAccessToBuilding && (
        <DataPointList onSensorClick={sensorClick}/>
      )}
    </div>
    <div className="TimeSeries">
      {hasAccessToBuilding && 
        (
          <SensorDetail sensorDetail={sensorName}></SensorDetail>
        )
      }
    </div>
    <div className="Hit">
      {hasAccessToBuilding &&
        <HIT ref={hitReference}/>
      }
      {hasAccessToBuilding && (
        <FloorPlan currentFloor={currentFloor} currentRoom={currentRoom}/>
      )}
    </div>
    <div className={timeSeriesOverlayClass}>
      {hasAccessToBuilding && (
        <TimeSeriesOverlay ref={timesSeriesOverlayReference}></TimeSeriesOverlay>
      )}
    </div>
    
    <div className="Logo"><img src="./img/siemens-logo.png" alt="Siemens Logo" id="SiemensLogoId"/></div>
    <div className="Title">Aspern Wayfinder</div>
    <div className="Login"></div>
  </div>
  );
}

export default App;