
import React, {  useState } from 'react';
import TimeSeriesDiagram from '../timeSeriesDiagram/TimeSeriesDiagram';

type sensorDetailProps = {
    sensorDetail:string;
}

export const SensorDetail:React.FunctionComponent<sensorDetailProps> = (props:sensorDetailProps) => {

    var [dataLoaded, setDataLoaded] = useState<boolean>(false);
    //var [dataURL, setDataUrl] = useState<string>("");
    //const diagramUrl = "https://vis.buildingtwin.siemens.com/line?title=Monthly%20Data%20of%20&id=ecdd27d18f6f1bc075aa2219176a722c1c5cd2def2dc7f8fc1984eda56884a73&roundSeconds=true&zoomOptions=true&zoomIntoLastDay=true&aggregation=mean&timeGrouping=1h&relativeDateRange=month&sourceIds=";

    function machwas(value:string)/*:string*/{
        if(value !== "default"){
            //if(value.includes('+')){
            //    value = value.split('+')[0];
            //}
            //setDataUrl(diagramUrl + value);
            setDataLoaded(true);
            //return dataURL;
        }/*else{
            //return "";
        }*/
    }

    const onChangeSensorDetailCall = React.useMemo/*<string>*/(() => machwas(props.sensorDetail),[props.sensorDetail]);

    // return (
    //     <div  style={{height:"100%"}}>
    //         <h3 style={{height:"7%",margin:"0px"}}>Time Series Data</h3>
    //         {dataLoaded && (
    //             <iframe style={{height:"94%", width:"100%", border:"none"}} src={dataURL}></iframe>
    //         )}
    //         {!dataLoaded && (
    //             <div>Choose a sensor</div>
    //         )}
    //     </div>
    // );

    return (
        <div  style={{height:"100%"}}>
            <h3 style={{height:"7%",margin:"0px"}}>Time Series Data</h3>
            {dataLoaded && (
                <div style={{height:"94%", width:"100%", border:"none"}}>
                    <TimeSeriesDiagram dorpDownActive={false}  deviceString={""} deviceArray={[""]}/>
                </div>
            )}
            {!dataLoaded && (
                <div>Choose a sensor</div>
            )}
        </div>
    );

    
}

export default SensorDetail;