import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
// import { APIInteraction } from '../../contexts/apiInteraction';
// import { useAuth0 } from "../../contexts/auth0-context";
import { PanoOverlay } from "../panoOverlay/PanoOverlay";
import spaceData from "../../data/API_Spaces.json";
import floorData from "../../data/API_Floors.json";
import "./FloorPlan.css";


type FloorPlanProps = {
    currentRoom: string;
    currentFloor: string;
}

export const FloorPlan: React.FunctionComponent<FloorPlanProps> = (props) => {
    //const { getTokenSilently } = useAuth0();
    const [isActivated, setIsActivated] = useState<boolean>(false);
    const [currentSVG, setcurrentSVG] = useState<string>("");
    const [floorPlanLinks, setfloorPlanLinks] = useState<Array<{ Name: string, Id: string, Link: string }>>(new Array<{ Name: string, Id: string, Link: string }>());
    const [spaces, setpsaces] = useState<any>({});
    const [space, setSpace] = useState<any>();
    const [svgId, setsvgId] = useState<string>("FloorplanId");
    const [showPano, setshowPano] = useState<boolean>(false);
    const [currentRoom, setCurrentRoom] = useState<string>("");
    const [panoDisabled, setPanoDisabled] = useState<boolean>(true);
    const s3PanoUrl = process.env.PUBLIC_URL +"/img/pano/";
    const s3FloorplanUrl = process.env.PUBLIC_URL +"/img/floorplan/";

    useEffect(() => {
        if (floorPlanLinks.length > 0) {
            var space = spaces.data.find((element: any) => element.attributes.name === props.currentRoom);
            setSpace(space);

            if (space) {
                var plan = floorPlanLinks.find((i) => i.Id === space.relationships.isPartOf.data.id);

                if (plan) {
                    setcurrentSVG(plan.Link);
                    
                }
            }
        }
        var element = document.getElementById(svgId);
        if(element){
            element.style.height = "100%";
            element.style.width = "100%";
        }
        setsvgId("FloorplanId");
        if(space?.attributes?.source?.id != undefined){

            const request = s3PanoUrl+space.attributes.source.id+".jpg";
            fetch(request).then((result:Response) => {
                if(result.status === 200){
                    setCurrentRoom(s3PanoUrl+space.attributes.source.id+".jpg");
                    setPanoDisabled(false);
                }else{
                    setPanoDisabled(true);
                }
            });
        }

    }, [props.currentRoom])
    
    useEffect(() => {
        let spaces = spaceData; 
        let floors = floorData;
        //let api = new APIInteraction();

        // async function fetchToken() {
        //     let token = await getTokenSilently();
        //     //let token = "";
        //     return token;
        // }

        // async function loadData() {
        //     console.log(api.getToken());
        //     spaces = await api.getSpaces();
        //     if (spaces.links != undefined){
        //         while (spaces.links.next != undefined) {
        //             const nextResult = await api.fetchNext(spaces.links.next);
        //             if (nextResult.data) {
        //                 spaces.data = spaces.data.concat(nextResult.data);
        //                 spaces.links.next = nextResult.links.next;
        //             }
        //         }
        //     }
            
        //     floors = await api.getFloors();
        // }


        // fetchToken().then((token:string)=>{
        //     api.setToken(token);
        setpsaces(spaces);
    
        if (floors && floors?.data.length > 0) {
            floors.data.map(async (item) => {
                if (item.relationships?.floorplan?.links?.self != undefined) {
                    //element = await api.getFloorPlan(item.relationships?.floorplan?.links?.self);
                    fetch(s3FloorplanUrl+item.attributes.name+".svg").then((fetchResults) => {
                        if(fetchResults.status === 200){
                            floorPlanLinks.push({ Name: item.attributes.name, Id: item.id, Link: fetchResults.url });
                        }
                    });
                }
            });
        }
        //     loadData().then(async () => {
        //         setpsaces(spaces);
    
        //         let element: any;
        //         if (floors && floors?.data.length > 0) {
        //             floors.data.map(async (item: any) => {
        //                 if (item.relationships?.floorplan?.links?.self != undefined) {
        //                     element = await api.getFloorPlan(item.relationships?.floorplan?.links?.self);
        //                     if(element){
        //                         floorPlanLinks.push({ Name: item.attributes.name, Id: item.id, Link: element.links.content });
        //                     }
        //                 }
        //             });
        //         }
        //     });
        // });
        

        setIsActivated(true);

    }, [])

    function ShowPano(){
        setshowPano(true);    
    }

    function ClosePano(){
        setshowPano(false);
    }

    function drawCurrentRoom(err: any, svg?: SVGSVGElement | undefined) {
        if (space) {
            var b = document.getElementsByTagName("g");
            for (const tag of Array.from(b)) {
                if (tag.getAttribute("btw:sourceId") === space.attributes.source.id) {
                    tag.style.fill = "#009999";
                    tag.style.opacity = "1";
                    tag.style.stroke = "yellow";
                    tag.style.fillOpacity = "1"
                }
            }
        }
        var element = document.getElementById(svgId);
        if(element){
            element.style.height = "100%";
            element.style.width = "100%";
        }
        setsvgId("FloorplanId");
    }
    
    return (
        <div style={{height:"86%",marginTop:"2%"}}>
            {isActivated && (
                <div style={{height:"100%"}}>
                    <div className="RoomName" >
                        Room Name: <strong>{props.currentRoom}</strong>
                        <button id="showPanoBtn" onClick={ShowPano} title="Activated, when panorama is available" disabled={panoDisabled}>Show Pano</button>
                    </div>
                    <div className="FloorPlan">
                        <ReactSVG id={svgId} src={currentSVG} afterInjection={drawCurrentRoom} />
                    </div>
                    {showPano && <PanoOverlay closeElement={ClosePano} currentRoom={currentRoom}/>}
                </div>
            )}
        </div>
    );
}

export default FloorPlan;
