import * as React from "react";
import { useEffect, useState } from "react";
import TreeMenu, { /*TreeMenuItem,*/ TreeNodeInArray } from "react-simple-tree-menu";
//import {APIInteraction} from '../../contexts/apiInteraction';
//import { useAuth0 } from "../../contexts/auth0-context";
import "./DataPointList.css";
import datapointData from "../../data/API_Datapoints.json";

type dataPointListProps = {
    onSensorClick:Function;
}

export const DataPointList:React.FunctionComponent<dataPointListProps> = (props) => {
    const [treeData, settreeData] = useState<TreeNodeInArray[]>([]);
    //const {getTokenSilently} = useAuth0();
    //const [api,setAPIInteraction] = useState<APIInteraction>(new APIInteraction());

    useEffect(()=>{
        if(treeData.length === 0){
            createTreeData();
        }
    });
    

    const createTreeData = async () => {
        //let token = await getTokenSilently();
        //let token = "";
        //api.setToken(token);
        // const results = await api.getDataPoints();
        // while (results.links.next !== undefined){
        //     const nextResult = await api.fetchNext(results.links.next);
        //     if(nextResult.data){
        //         results.data = results.data.concat(nextResult.data);
        //         results.links.next = nextResult.links.next;
        //     }
        // }

        const results = datapointData;
        const treeDatalocal:TreeNodeInArray[] = [];

        if(results.data.length !== 0 ){
            results.data.map((item:any) => {
                treeDatalocal.push({
                    key: item.id,
                    label: item.attributes.source.id,
                    type: "datapoint"
                })
            });
            
        }

        settreeData(treeDatalocal);
    }
    
    const onclickSchmick = async (ele:any) =>{
        if(ele.label){
            props.onSensorClick(ele.label);
        }
    }

    return(
        <div style={{height:"100%",overflow:"scroll"}}>
            <TreeMenu data={treeData} onClickItem={onclickSchmick}></TreeMenu>
        </div>
    );
}

export default DataPointList;