import React, { useRef } from 'react';
import "./TimeSeriesOverlay.css";
import TimeSeriesDiagram from "../timeSeriesDiagram/TimeSeriesDiagram";

type timeSeriesOverlayProps = {}
type timeSeriesOverlayState = {
    isActivated:boolean;
}

export class TimeSeriesOverlay extends React.Component<timeSeriesOverlayProps,timeSeriesOverlayState>/*<TimeSeriesOverlayProps>*/{
    private deviceArray:string[] = [];
    private myRef = React.createRef<HTMLDivElement>();

    constructor(props:timeSeriesOverlayProps){
        super(props);
        this.state = {isActivated:false};
        this.myRef = React.createRef<HTMLDivElement>();
    }

    public initOverlay = (deviceString:string) => {
        this.deviceArray = deviceString.split(';').filter((item:string) => item.length > 0);
        this.setState({isActivated:true});
    }

    public closeMe = () => {
        this.setState({isActivated:false});
    }


    render(){
        return(
            <div>
                {this.state.isActivated && (
                    <div className="overlay">
                        <button className="closeOverlayButton" onClick={this.closeMe}>X</button>
                        <div className="diagramContainer">
                            {this.deviceArray.map((item:string, index:number) => {
                                if(index < 4){
                                    return <div className={"diagram_" + index}><TimeSeriesDiagram dorpDownActive={true} key={item} deviceString={item} deviceArray={this.deviceArray}/></div>
                                }  
                            })}
                            {this.deviceArray.length === 0 && (
                                <div className="noSensorsBanner">
                                    No sensors detected.
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


export default TimeSeriesOverlay;