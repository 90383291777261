import React from 'react';
import "./HIT.css";


type HITProps = {}
type HITState = {
    isActivated:boolean;
    hasSensor:boolean;
    type:string;
}

export class HIT extends React.Component<HITProps,HITState>{

    private type = 'No type detected.';
    private hitURL = 'https://hit.sbt.siemens.com/RWD/app.aspx?RC=CH&lang=de&MODULE=Product&ACTION=Search&VALUE=';
    constructor(props:HITProps){
        super(props);
        this.state = {isActivated:true, type:this.type, hasSensor:false};
    }

    public changeType = (type:string) => {
        this.type = type;
        this.setState({hasSensor:true});
        this.setState({type:this.type})
    }

    public closeMe = () => {
        this.setState({hasSensor:false});
    }


    render(){
        return(
            <div style={{height:"12%"}}>
                {this.state.isActivated && (
                    <div>
                        {!this.state.hasSensor && (
                            <div>
                                <div className="HITTitle"><strong>Product Information</strong></div>
                                <div className="HITLink">Select a sensor to see type & product-page</div>
                            </div>
                        )}
                        {this.state.hasSensor && (
                            <div>
                                <div className="HITTitle">Sensortype: <strong>{this.state.type}</strong></div>
                                <div className="HITLink"><a href={this.hitURL + this.state.type} rel="noopener noreferrer" target="_blank">Link to Type {this.state.type} in HIT</a></div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}


export default HIT;