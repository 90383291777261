/* eslint no-unused-expressions: 0 */
/* eslint no-restricted-globals: 0 */
/* eslint no-undef: 0 */
/* eslint-disable */
export const UnityInterface = {
    init: async function(){
        var buildUrl = "Build";
        var loaderUrl = buildUrl + "/TCBuild.loader.js";
        var config = {
        dataUrl: buildUrl + "/TCBuild.data",
        frameworkUrl: buildUrl + "/TCBuild.framework.js",
        codeUrl: buildUrl + "/TCBuild.wasm",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "DefaultCompany",
        productName: "AspernWeb",
        productVersion: "0.1",
      };

        var container = document.querySelector("#unity-container");
        var canvas = document.querySelector("#unity-canvas");
        var loadingBar = document.querySelector("#unity-loading-bar");
        var progressBarFull = document.querySelector("#unity-progress-bar-full");

        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        container.className = "unity-mobile";
        config.devicePixelRatio = 1;
        } else {
        // canvas.style.width = "100%";
        // canvas.style.height = "100%";
        }
        loadingBar.style.display = "block";

        var script = document.createElement("script");
        script.src = loaderUrl;

        var instance = createUnityInstance(canvas, config, (progress) => {
            progressBarFull.style.width = 100 * progress + "%";
            //console.log(progress);
        });
        
        loadingBar.style.display = "none";
        
        document.body.appendChild(script);
        return instance;
    },
    SendMessage: function(instance, message){
        //instance.sendMessage(message);
        instance.SendMessage("PlayerHandler","NavigateTo",message);
        //instance.SendMessage("PlayerHandler","NavigateTo",bacnetId);
    },
    NotFound: function (){
      document.getElementById("statusUnity").innerText = "Status: Sensor nicht gefunden.";
    }
};
