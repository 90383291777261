import React from 'react';
import {UnityInterface} from './unityInterface.js';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Unity.css';

type unityProps = {
    isOn:boolean;
    sensorName:string;
    onChange:any;
    onNearMeResults:Function;
    onExportTypeResults:Function;
    onRoomChanged:Function;
}

type unityState = {
    unityIsOn:boolean;
    sensorName:string;
}

export class Unity extends React.Component<unityProps,unityState>{
    unityInstancePromise:any;
    unityInstance:any;
    bacnetId:string = "default";

    public ArrivedAtDevice = (e:React.MouseEvent<HTMLButtonElement,MouseEvent>) => {
        var button = e.target as HTMLInputElement;
        this.bacnetId = button.textContent!;
        this.props.onChange(this.bacnetId);
        this.setState({sensorName: this.bacnetId});
        toast.success("Arrived at \n" + this.bacnetId, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    }

    private NoDeviceFound = (e:React.MouseEvent<HTMLButtonElement,MouseEvent>) => {
        toast.error("Location of the sensor could not be found", {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    }

    private NearMe = (e:React.MouseEvent<HTMLButtonElement,MouseEvent>) => {
        var button = e.target as HTMLInputElement;
        var nearMeDevices = button.textContent!;
        this.props.onNearMeResults(nearMeDevices);
    }
    
    private ExportType = (e:React.MouseEvent<HTMLButtonElement,MouseEvent>) =>{
        var button = e.target as HTMLInputElement;
        var type = button.textContent!;
        this.props.onExportTypeResults(type);
    }

    private OnRoomChanged = (e:React.MouseEvent<HTMLButtonElement,MouseEvent>) =>{
        var button = e.target as HTMLInputElement;
        var roomName = button.textContent!;
        this.props.onRoomChanged(roomName);
    }

    constructor(props:unityProps){
        super(props);
        this.state = {unityIsOn:props.isOn, sensorName:props.sensorName};
    }
    
    public navigateInUnityTo (sensorName:string){
        this.sendMessage(sensorName);
    }

    componentDidMount(){
        if(this.state.unityIsOn){
            this.initApp();
        }
    }

    render(){
        return (
            <div id="unity-container" style={{height:"100%", width:"100%"}} className="unity-desktop">
                <canvas id="unity-canvas"style={{height:"100%", width:"100%"}} ></canvas>
                <div id="unity-loading-bar">
                    <div id="unity-logo"></div>
                    <div id="unity-progress-bar-empty">
                    <div id="unity-progress-bar-full"></div>
                    </div>
                </div>
                <button id="hiddenInterfaceButtonArrivedAtDevice" onClick={this.ArrivedAtDevice} className="hiddenButton"></button>
                <button id="hiddenInterfaceButtonNoDeviceFound" onClick={this.NoDeviceFound} className="hiddenButton"></button>
                <button id="hiddenInterfaceButtonNearMe" onClick={this.NearMe} className="hiddenButton"></button>
                <button id="hiddenInterfaceButtonExportType" onClick={this.ExportType} className="hiddenButton"></button>
                <button id="hiddenInterfaceButtonRoomName" onClick={this.OnRoomChanged} className="hiddenButton"></button>
                <ToastContainer 
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }

    public async initApp(){
        this.unityInstancePromise = UnityInterface.init();
        this.unityInstance = await this.unityInstancePromise;
    }
      
    private sendMessage(message:string){
        if(this.unityInstance !== undefined){
            //this.unityInstance.SendMessage(this.unityInstance,message);
            this.unityInstance.SendMessage("PlayerHandler","NavigateTo",message);
        }
    }
}