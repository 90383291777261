import * as React from "react";
import { useState } from "react";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";
import "./TimeSeriesDiagram.css";
import sampleDiagram from '../../data/timeseries_sample.png';

type timeSeriesDiagramprops = {
    deviceArray: string[];
    deviceString: string;
    dorpDownActive: boolean;
}

export const TimeSeriesDiagram:React.FunctionComponent<timeSeriesDiagramprops> = (props) => {
    var [selectedDevice, setselectedDevice] = useState<string>(props.deviceString);
    //var diagramUrl = "https://vis.buildingtwin.siemens.com/line?title=Monthly%20Data%20of%20&id=ecdd27d18f6f1bc075aa2219176a722c1c5cd2def2dc7f8fc1984eda56884a73&roundSeconds=true&zoomOptions=true&zoomIntoLastDay=true&aggregation=mean&timeGrouping=1h&relativeDateRange=month&sourceIds=";
    //var [dataUrl, setDataUrl] = useState<string>(diagramUrl + selectedDevice);
    var [hideIframe, setHideIframe] = useState<boolean>(false);
    
    // React.useEffect(()=>{
    //     setDataUrl(diagramUrl + selectedDevice.toUpperCase());
    // });

    const loadDiagram = (e:Option) => {
        setselectedDevice(e.value);
        setHideIframe(false);
    };

    const hideDiagram = () => {
        setHideIframe(true);
    }
    

    // return(
    //     <div className="timeSeriesDiagram">
    //         <div className="dropdownSeriesDiv"><Dropdown options={props.deviceArray} onChange={loadDiagram} value={selectedDevice} onFocus={hideDiagram} /></div>
    //         <div className="iframeSeriesDiv">{!hideIframe && <iframe className="iframe" src={dataUrl}></iframe>}</div>
    //     </div>
    // )
    return(
        <div className="timeSeriesDiagram">
            {props.dorpDownActive && (
                <div className="dropdownSeriesDiv"><Dropdown options={props.deviceArray} onChange={loadDiagram} value={selectedDevice} onFocus={hideDiagram} /></div>
            )}
            <div className="iframeSeriesDiv">{!hideIframe && <img className="iframe" src={sampleDiagram} alt="sample diagram"/>}</div>
        </div>
    )
}

export default TimeSeriesDiagram;